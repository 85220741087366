// color for me badge
$color0: #95a5a6;

// colors for other user's badges
$color1: #2980b9;
$color2: #e67e22;
$color3: #27ae60;
$color4: #e74c3c;
$color5: #8e44ad;
$color6: #3498db;
$color7: #f39c12;
$color8: #2ecc71;
$color9: #c0392b;
$color10: #9b59b6;

$colors: $color0 $color1 $color2 $color3 $color4 $color5 $color6 $color7 $color8 $color9 $color10;

@for $i from 1 through 11 {
  $no: $i - 1;

  $color: rgba(nth($colors, $i), 0.6);
  $color_hover: rgba(nth($colors, $i), 0.8);
  $color_intense: rgba(nth($colors, $i), 1);

  .user-badge-outer .user-badge.user-#{$no} {
    background-color: $color;
  }
  .user-badge-outer:hover .user-badge.user-#{$no} {
    background-color: $color;
    -webkit-box-shadow: 0 0 0 2px $color;
    -moz-box-shadow: 0 0 0 2px $color;
    box-shadow: 0 0 0 2px $color;
  }
  .user-badge-outer .user-badge.user-#{$no}:hover {
    background-color: $color_intense;
    -webkit-box-shadow: 0 0 0 4px $color_intense;
    -moz-box-shadow: 0 0 0 4px $color_intense;
    box-shadow: 0 0 0 4px $color_intense;
  }
  .user-badge.user-#{$no} {
    background-color: $color;
  }
}

.user-badge {
  margin-left: 1px;
  margin-right: 4px;
}

.user-badge-outer-sm .user-badge {
  height: 20px;
  width: 20px;
  line-height: 20px;
  //-moz-border-radius: 10px;
  //border-radius: 10px;
  font-size: 0.85rem;
}

.user-badge-outer-md .user-badge {
  height: 30px;
  width: 30px;
  line-height: 30px;
  //-moz-border-radius: 15px;
  //border-radius: 15px;
  font-size: 1.1rem;
}

.user-badge-outer-lg .user-badge {
  height: 40px;
  width: 40px;
  line-height: 40px;
  //-moz-border-radius: 20px;
  //border-radius: 20px;
  font-size: 1.5rem;
}

.user-badge-outer-xxl .user-badge {
  height: 150px;
  width: 150px;
  //-moz-border-radius: 75px;
  //border-radius: 75px;
  font-size: 4.5rem;
}

.user-badge-outer:not(:hover) .user-badge-outer-hover-only {
  visibility: hidden;
}

.show-avatar-labels-on-hover:not(:hover) {
  .avatar-label-hidden-until-hover:not(:hover) {
    visibility: hidden;
  }

  .avatar.light:not(:hover) {
    opacity: 0.65;
    filter: alpha(opacity=65);
  }
}

.user-badge-outer {
  .besides-badge {
    line-height: 30px !important;
  }

  &.user-badge-outer-sm .besides-badge {
    line-height: 20px !important;
  }

  &.user-badge-outer-md .besides-badge {
    line-height: 30px !important;
  }

  &.user-badge-outer-lg .besides-badge {
    line-height: 40px !important;
  }

  &.user-badge-outer-xxl .besides-badge {
    line-height: 150px !important;
  }
}
