/*************
  bootstrap theme
 *************/
/* jQuery bootstrap Theme */
.tablesorter-mavora {
  width: 100%;
}

.tablesorter-mavora thead th,
.tablesorter-mavora thead td,
.tablesorter-mavora tfoot th,
.tablesorter-mavora tfoot td {
  font: 14px/20px Arial, Sans-serif;
  font-weight: bold;
  padding: 4px;
  margin: 0 0 18px;
}

.tablesorter-mavora th.tablesorter-header:not([data-sorter="false"]) {
  cursor: pointer;
}

.tablesorter-mavora .sorter-false {
  cursor: bootstrap;
}

.tablesorter-mavora .tablesorter-header.sorter-false i.tablesorter-icon {
  display: none;
}

.tablesorter-mavora th .tablesorter-header-inner {
  position: relative;
  padding: 0px 24px 0px 0px;
}

/* bootstrap uses <i> for icons */
.tablesorter-mavora .tablesorter-header i.tablesorter-icon {
  font-size: 11px;
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -7px; /* half the icon height; older IE doesn't like this */
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  line-height: 14px;
  display: inline-block;
}

/* black unsorted icon */
.tablesorter-mavora .bootstrap-icon-unsorted {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAYAAAD5YeaVAAAA20lEQVR4AWJABpKSkoxALCstLb0aUAsZaCAMhVEY6B0amx8YZWDDEDSBa2AGe7XeIiAAClYwVGBvsAcIllsf/mvcC9DgOOd8h90fxWvngVEUbZIkuWRZZlE8eQjcisgZMM9zi+LJ6ZfwegmWZflZDugdHMfxTcGqql7TNBlUB/QObtv2VBSFrev6OY7jngzFk9OT/fn73fWYpqnlXNyXDMWT0zuYx/Bvel9ej+LJ6R08DMOu67q7DkTkrSA5vYPneV71fX/QASdTkJwezhs0TfMARn0wMDDGXEPgF4oijqwM5YjNAAAAAElFTkSuQmCC);
}

/* white unsorted icon */
.tablesorter-mavora .icon-white.bootstrap-icon-unsorted {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAYAAAD5YeaVAAAAe0lEQVR4AbXQoRWDMBiF0Sh2QLAAQ8SxJGugWSA6A2STW1PxTsnB9cnkfuYvv8OGC1t5G3Y0QMP+Bm857keAdQIzWBP3+Bw4MADQE18B6/etRnCV/w9nnGuLezfAmXhABGtAGIkruvk6auIFRwQJDywllsEAjCecB20GP59BQQ+gtlRLAAAAAElFTkSuQmCC);
}

/* since bootstrap (table-striped) uses nth-child(), we just use this to add a zebra stripe color */
.tablesorter-mavora > tbody > tr.odd > td,
.tablesorter-mavora > tbody > tr.tablesorter-hasChildRow.odd:hover ~ tr.tablesorter-hasChildRow.odd ~ .tablesorter-childRow.odd > td {
  background-color: #f9f9f9;
}

.tablesorter-mavora > tbody > tr.hover > td,
.tablesorter-mavora > tbody > tr.odd:hover > td,
.tablesorter-mavora > tbody > tr.even:hover > td,
.tablesorter-mavora > tbody > tr.tablesorter-hasChildRow.odd:hover ~ .tablesorter-childRow.odd > td,
.tablesorter-mavora > tbody > tr.tablesorter-hasChildRow.even:hover ~ .tablesorter-childRow.even > td {
  background-color: #f5f5f5;
}

.tablesorter-mavora > tbody > tr.even > td,
.tablesorter-mavora > tbody > tr.tablesorter-hasChildRow.even:hover ~ tr.tablesorter-hasChildRow.even ~ .tablesorter-childRow.even > td {
  background-color: #fff;
}

/* processing icon */
.tablesorter-mavora .tablesorter-processing {
  background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

/* caption */
.caption {
  background-color: #fff;
}

/* filter widget */
.tablesorter-mavora .tablesorter-filter-row input.tablesorter-filter,
.tablesorter-mavora .tablesorter-filter-row select.tablesorter-filter {
  width: 98%;
  margin: 0;
  padding: 4px 6px;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: height 0.1s ease;
  -moz-transition: height 0.1s ease;
  -o-transition: height 0.1s ease;
  transition: height 0.1s ease;
}

.tablesorter-mavora .tablesorter-filter-row .tablesorter-filter.disabled {
  background-color: #eee;
  color: #555;
  cursor: not-allowed;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
  box-sizing: border-box;
  transition: height 0.1s ease;
}

.tablesorter-mavora .tablesorter-filter-row {
  background-color: #efefef;
}

.tablesorter-mavora .tablesorter-filter-row td {
  background-color: #efefef;
  line-height: normal;
  text-align: center;
  padding: 4px 6px;
  vertical-align: middle;
  -webkit-transition: line-height 0.1s ease;
  -moz-transition: line-height 0.1s ease;
  -o-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}

/* hidden filter row */
.tablesorter-mavora .tablesorter-filter-row.hideme td {
  padding: 2px; /* change this to modify the thickness of the closed border row */
  margin: 0;
  line-height: 0;
}

.tablesorter-mavora .tablesorter-filter-row.hideme * {
  height: 1px;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  /* don't use visibility: hidden because it disables tabbing */
  opacity: 0;
  filter: alpha(opacity=0);
}

/* rows hidden by filtering (needed for child rows) */
.tablesorter .filtered {
  display: none;
}

/* pager plugin */
.tablesorter-mavora .tablesorter-pager select {
  padding: 4px 6px;
}

.tablesorter-mavora .tablesorter-pager .pagedisplay {
  border: 0;
}

/* tfoot i for pager controls */
.tablesorter-mavora tfoot i {
  font-size: 11px;
}

/* ajax error row */
.tablesorter .tablesorter-errorRow td {
  text-align: center;
  cursor: pointer;
  background-color: #e6bf99;
}
