/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-bottom: 20px;

  /* Ensure that body uses the full height. */
  min-height:calc(100vh - 50px);
}

dd {
  margin: 0 0 0 0;
  padding: 0 0 0.5em 0;
}

.brand {
  float: left;
  padding-left: 5px;
  padding-right: 15px;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  #disclaimer {
    position: absolute;
    bottom: 50px;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 80px;
    /*background-color: #f5f5f5;*/
  }

  /* Ensure that disclaimer has enough space */
  #disclaimer-placeholder {
    margin-bottom: 60px;
  }
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 50px;
  background-color: #f5f5f5;
}

.container .text-muted {
  margin: 15px 0;
}

#footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

#disclaimer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.dropzone-box {
  margin: 0px;
  border: 3px dashed #A2A2A2;
  border-radius: 0px;
  min-height: 200px;
  text-align: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.dropzone-box p {
  color: #A2A2A2;
}

.is-hovered, .is-hovered li {
  background: rgb(51, 122, 183);
}

.is-hovered .dropzone-box {
  border: 3px dashed #fff;
}

.is-hovered, .is-hovered p, .is-hovered a, .is-hovered:hover {
  color: #fff;
}

.is-hovered .btn {
  border-color: #fff;
}

.dropzone-inner {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.text-left {
  text-align: left;
}

/* Documents table */
.documents-table th:not(:nth-child(2)), .clients-table th:not(:first-child):not(:nth-child(2)), .cases-table th:not(:first-child):not(:nth-child(2)), .mails-table th:not(:first-child):not(:nth-child(2)), .groups-table th:not(:first-child):not(:nth-child(1)),
.templates-table th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)), .notifications-table th:not(:first-child):not(:nth-child(3)):not(:nth-child(3)) {
  width: 1px;
}

.small-column {
  width: 1px;
}

.documents-table .progress-bar {
  text-align: left;
  padding-left: 5px;
}

.document-unread > td {
  font-weight: bold;
}

.document-unread > span {
  font-weight: bold;
}

.document-unread > a, a.document-unread {
  font-weight: bold;
}

.document-unread > td > .modal {
  font-weight: normal;
}

.visible-if-read {
  display: none;
}

.document-read .visible-if-read {
  display: inline;
}

.visible-if-unread {
  display: none;
}

.document-unread .visible-if-unread {
  display: inline;
}

.visible-if-downloading {
  display: none;
}

.document-downloading td.visible-if-downloading {
  display: table-cell;
}

.document-downloading .hidden-if-downloading {
  display: none;
}

a + .success {
  display: none;
}

a[href^="blob:"] + .success {
  display: inline;
}

.nomargin {
  margin: 0px;
}

.visible-if-dragging, .visible-if-dropzone {
  display: none;
}

.is-dragging .hidden-if-dragging {
  display: none;
}

.is-dragging .visible-if-dragging {
  display: block;
}

.is-dragging tr.visible-if-dragging {
  display: table-row;
}

.is-dragging tbody.visible-if-dragging {
  display: table-row-group;
}

input[readonly].text-cursor {
  cursor: text;
  background-color: white;
}

.show-if-only-child {
  display: none;
}

.show-if-only-child:only-child {
  display: table-row;
}

.flex {
  display: flex;
}

.vertical-align {
  display: inline-flex;
  justify-content: center;
}

body {
  word-wrap: break-word;
}

.clickable {
  cursor: pointer;
  cursor: hand;
}

.clickable-exclude {
  cursor: default;
}

.table-hover > tbody.no-hover > tr:hover,
.table-hover > tbody > tr.no-hover:hover,
.table-hover > tbody > tr:hover > td.no-hover {
  background-color: inherit;
}

.spacer {
  margin-top: 25px;
}

.text-gray {
  color: #A2A2A2;
}

.hoverable:not(:hover) .hover-only {
  visibility: hidden;
}

.table-hover > tbody > tr.alert-info:hover {
  background: rgb(207, 227, 237);
}

/* Breadcrumbs from http://bootsnipp.com/snippets/featured/triangle-breadcrumbs-arrows */
.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

.btn-breadcrumb .btn {
  padding: 6px 12px 6px 24px;
}

.btn-breadcrumb .btn:first-child {
  padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
  padding: 6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
  border-left: 10px solid #fff;
}

.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
  border-left: 10px solid #ebebeb;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
  border-left: 10px solid #adadad;
}

/* The responsive part */

.btn-breadcrumb > * > div {
  /* With less: .text-overflow(); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-breadcrumb > *:nth-child(n+2) {
  display: none;
}

/* === For phones =================================== */
@media (max-width: 767px) {
  .btn-breadcrumb > *:nth-last-child(-n+2) {
    display: block;
  }

  .btn-breadcrumb > * div {
    max-width: 60px;
  }
}

/* === For tablets ================================== */
@media (min-width: 768px) and (max-width: 991px) {
  .btn-breadcrumb > *:nth-last-child(-n+4) {
    display: block;
  }

  .btn-breadcrumb > * div {
    max-width: 100px;
  }
}

/* === For desktops ================================== */
@media (min-width: 992px) {
  .btn-breadcrumb > *:nth-last-child(-n+6) {
    display: block;
  }

  .btn-breadcrumb > * div {
    max-width: 170px;
  }
}

select.form-control {
  color: #999;
}

select.no-placeholder {
  color: black;
}

select option {
  color: black;
}

select option[value=''] {
  color: #999;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #999;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

.form-inline .form-control {
  display: block; /* allows labels to sit on input when inline */
  margin-bottom: 15px; /* gives padding to bottom of inline inputs */
}

.jumbotron {
  margin-top: -20px;
}

.tablesorter {
  margin-top: 1px;
}

@media (max-width: 767px) {
  .pull-xs-left {
    float: left;
  }

  .pull-xs-right {
    float: right;
  }

  .text-right-xs {
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .pull-sm-left {
    float: left;
  }

  .pull-sm-right {
    float: right;
  }

  .text-right-sm {
    text-align: right;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .pull-md-left {
    float: left;
  }

  .pull-md-right {
    float: right;
  }

  .text-right-md {
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .pull-lg-left {
    float: left;
  }

  .pull-lg-right {
    float: right;
  }

  .text-right-lg {
    text-align: right;
  }
}

.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/* https://codepen.io/dimbslmh/full/mKfCc */

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

/* Fix problem "only first page of modals is getting printed" based on http://stackoverflow.com/a/39629374 */
@media print {
  .modal {
    overflow: visible !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
  }

  .modal-dialog {
    width: 100% !important;
    margin: 0;
  }

  .modal-backdrop {
    background-color: white;
    opacity: 1 !important;
  }
}

.table.documents-table > thead > tr > th,
.table.documents-table > thead > tr > td,
.table.documents-table > tbody > tr > th,
.table.documents-table > tbody > tr > td,
.table.documents-table > tfoot > tr > th,
.table.documents-table > tfoot > tr > td {
  border-top: 0px solid #ddd;
}

.table.documents-table > thead > tr,
.table.documents-table > tbody > tr,
.table.documents-table > tfoot > tr {
  border-top: 1px solid #ddd;
}

.table.documents-table > thead > tr:first-child {
  border-top: 0px solid #ddd;
}

.table.cases-table > thead > tr > th,
.table.cases-table > thead > tr > td,
.table.cases-table > tbody > tr > th,
.table.cases-table > tbody > tr > td,
.table.cases-table > tfoot > tr > th,
.table.cases-table > tfoot > tr > td {
  border-top: 0px solid #ddd;
}

.table.cases-table > thead,
.table.cases-table > tbody,
.table.cases-table > tfoot {
  border-top: 1px solid #ddd;
}

.table.cases-table > thead:first-child {
  border-top: 0px solid #ddd;
}

.table > tbody + tbody {
  border-top: 0px solid #ddd;
}

.table > tbody.show-if-only-child, .table > tbody > tr.show-if-only-child {
  border-top: 0px solid #ddd;
}

.panel-info-row {
  border: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  margin-bottom: 0px;
}

.panel-empty-row {

}

.white {
  color: white;
}


.hidden-form-group {
  visibility:hidden;height:0;width:0;margin:0;
}

.menuItem {
  cursor: pointer;
}
