$mdc-theme-primary: darken(#428bca, 6.5%);
//$mdc-theme-primary: red;
//$mdc-theme-on-primary: darken($mdc-theme-primary, 5%);
$mdc-theme-on-primary: white;
$mdc-theme-secondary: darken(#428bca, 6.5%);
//$mdc-theme-error: darken(#ff0000, 6.5%);
//$mdc-theme-on-secondary: darken($mdc-theme-secondary, 5%);
$mdc-theme-on-secondary: white;
$mdc-checkbox-mark-color: white;

@import "~@material/theme/mdc-theme";

@import "~@material/button/mdc-button";
@import "~@material/icon-button/mdc-icon-button";
@import "~@material/textfield/mdc-text-field";
@import "~@material/textfield/helper-text/mdc-text-field-helper-text";
@import "~@material/select/mdc-select";
@import "~@material/select/helper-text/mdc-select-helper-text";
@import "~@material/floating-label/mdc-floating-label";
@import "~@material/menu-surface/mdc-menu-surface";

@import "~@material/snackbar/mdc-snackbar";
@import "~@material/top-app-bar/mdc-top-app-bar";
@import "~@material/dialog/mdc-dialog";
@import "~@material/list/mdc-list";

@import "~@material/form-field/mdc-form-field";
@import "~@material/checkbox/mdc-checkbox";
@import "~@material/switch/mdc-switch";

@import "~@material/card/mdc-card";

@import "~@material/ripple/mdc-ripple";
@import "~@material/elevation/mdc-elevation";

@import "~@material/tab-bar/mdc-tab-bar";
@import "~@material/tab-scroller/mdc-tab-scroller";
@import "~@material/tab-indicator/mdc-tab-indicator";
@import "~@material/tab/mdc-tab";

@import "~@material/menu-surface/mdc-menu-surface";

@import "~@material/typography/mdc-typography";

// Fix html default font size overwritten by bootstrap.
html {
  font-size: 16px;
}

.mdc-text-field--outlined-fullwidth {
  width: 100%;
}

.mdc-select--outlined-fullwidth {
  width: 100%;
}

// Move label when text-field gets auto-filled in Chrome.
.mdc-text-field__input:-webkit-autofill + .mdc-notched-outline > .mdc-notched-outline__notch > .mdc-floating-label {
  transform: translateY(-130%) scale(.75);
  left: 4px;
}

//.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label.mdc-floating-label--float-above {
//  left: 4px;
//}

// Support leading icons that are outside the textfield.
.mdc-text-field--container {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}

.mdc-text-field--container > .mdc-text-field--outside-leading-icon {
  color: $mdc-text-field-icon-color;
  display: block;
  line-height: $mdc-text-field-height;
  padding-right: 16px;
  vertical-align: middle;
}

// Fix margin-top of outlined textareas.
.mdc-text-field--textarea--outlined {
  @include mdc-text-field-outlined_;
}

.mdc-text-field--textarea--outlined textarea.mdc-text-field__input {
  margin-top: 0;
}

// Fix margin-bottom of mdc-form-field labels.
.mdc-form-field label {
  margin-bottom: 0;
}

// Fix font-weight of mdc-form-field labels.
.mdc-form-field label {
  font-weight: 400;
}

// Support trailing buttons in text fields.
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon.mdc-text-field__trailing-button {
  pointer-events: auto;
  font-size: 24px;
  color: lightgrey;
  bottom: 4px;
  right: 4px;
}

// Disable text-decoration for mdc buttons.
.mdc-button:hover, .mdc-button:focus {
  text-decoration: none;
}


.button-container {
  text-align: right;
}

.button-container .mdc-button {
  margin: 5px;
}

.mdc-button.error-button {
  @include mdc-button-ink-color($mdc-theme-error);
  @include mdc-states(error);
}

.mdc-checkbox-helper-line {
  margin-top: -10px;
  margin-bottom: 15px;
  padding-left: 44px;
}
